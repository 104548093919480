import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  SelectionOption,
  CalMaxIcon,
  StyleText,
  StyleDescription,
  ColorContainer,
} from 'src/templates/CalendarProductPage/styles'
import { useStyleConfig } from '../../templates/SnapFramePage/styleConfig'
import ColorSelectionItem from '../ColorSelectionItem'

const StyleOption = ({
  style,
  color,
  isSelected,
  handleSelection,
  smallRadio,
  isOutOfStock,
  plp,
}) => {
  const styleConfig = useStyleConfig()
  const { description, basePrice, snapBasePrice } = styleConfig[style]
  const images = plp ? styleConfig[style]?.image : styleConfig[style]?.snapImage
  const image = isSelected && images[color] ? images[color] : images[Object.keys(images)[0]]

  return (
    <SelectionOption
      selected={isSelected}
      key={color}
      onClick={() => handleSelection(style, color)}
    >
      <CalMaxIcon>
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt={`${style} ${color} Frame 2`}
        />
      </CalMaxIcon>
      <ColorContainer>
        <StyleText>{style}</StyleText>
        {Object.keys(images).map((colorKey, i) => (
          <ColorSelectionItem
            key={`${i}-${colorKey}-${smallRadio}`}
            variant={colorKey}
            isOutOfStock={false}
            isSelected={color === colorKey}
            smallRadio={smallRadio}
            onSelectColor={() => handleSelection(style, colorKey)}
          />
        ))}
      </ColorContainer>
      <StyleText blue>${plp ? basePrice : snapBasePrice}</StyleText>
      <StyleDescription>{description}</StyleDescription>
    </SelectionOption>
  )
}

StyleOption.propTypes = {
  style: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleSelection: PropTypes.func.isRequired,
  smallRadio: PropTypes.bool,
  isOutOfStock: PropTypes.bool,
  plp: PropTypes.bool,
}

export default StyleOption
