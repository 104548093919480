import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'gatsby-plugin-react-i18next'
import { CustomizationOption, Subheader } from 'src/components/FrameProductForm/styles'
import { Quantity, QuantityInput, QuantityButton } from './styles'

const QuantitySelection = ({ quantityVal, decrementQuantity, incrementQuantity }) => (
  <CustomizationOption>
    <Subheader>
      <Trans>Quantity</Trans>
    </Subheader>
    <Quantity>
      <QuantityInput>
        <QuantityButton type="button" onClick={decrementQuantity}>
          -
        </QuantityButton>
        {quantityVal}
        <QuantityButton type="button" onClick={incrementQuantity}>
          +
        </QuantityButton>
      </QuantityInput>
    </Quantity>
  </CustomizationOption>
)

QuantitySelection.propTypes = {
  quantityVal: PropTypes.number.isRequired,
  decrementQuantity: PropTypes.func.isRequired,
  incrementQuantity: PropTypes.func.isRequired,
}

export default QuantitySelection
