import { graphql, useStaticQuery } from 'gatsby'

export const useStyleConfig = () => {
  const {
    classic,
    cover,
    wood,
    shadowBox,
    classicBlack,
    classicWhite,
    classicSage,
    classicLagoon,
    coverBlack,
    coverWhite,
    woodWalnut,
    woodBirch,
    shadowBoxAluminum,
    shadowBoxCharcoal,
    individualClassicBlack,
    individualCoverBlack,
    individualShadowBoxAluminum,
    individualWoodBirch,
    individualWoodWalnut,
  } = useStaticQuery(graphql`
    query StyleConfigImages {
      classic: file(relativePath: { eq: "frame2/classic-style.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      cover: file(relativePath: { eq: "frame2/cover-style.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      wood: file(relativePath: { eq: "frame2/wood-style.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      shadowBox: file(relativePath: { eq: "frame2/shadowbox-style.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      classicBlack: file(relativePath: { eq: "frame2/style/classic-black.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      classicWhite: file(relativePath: { eq: "frame2/style/classic-white.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      classicSage: file(relativePath: { eq: "frame2/style/classic-sage.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      classicLagoon: file(relativePath: { eq: "frame2/style/classic-lagoon.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      coverBlack: file(relativePath: { eq: "frame2/style/cover-black.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      coverWhite: file(relativePath: { eq: "frame2/style/cover-white.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      woodBirch: file(relativePath: { eq: "frame2/style/wood-birch.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      woodWalnut: file(relativePath: { eq: "frame2/style/wood-walnut.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      shadowBoxAluminum: file(relativePath: { eq: "frame2/style/shadowbox-aluminum.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      shadowBoxCharcoal: file(relativePath: { eq: "frame2/style/shadowbox-charcoal.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      individualClassicBlack: file(
        relativePath: { eq: "frame2/style/individual-classic-black.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      individualCoverBlack: file(relativePath: { eq: "frame2/style/individual-cover-black.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      individualShadowBoxAluminum: file(
        relativePath: { eq: "frame2/style/individual-shadow-box-aluminum.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      individualWoodBirch: file(relativePath: { eq: "frame2/style/individual-wood-birch.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      individualWoodWalnut: file(relativePath: { eq: "frame2/style/individual-wood-walnut.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  const styleConfig = {
    Classic: {
      image: {
        Black: classicBlack,
        White: classicWhite,
        Sage: classicSage,
        Lagoon: classicLagoon,
      },
      snapImage: {
        Black: classic,
        White: classic,
        Sage: classic,
        Lagoon: classic,
      },
      snapFrameIndividualImage: {
        Black: individualClassicBlack,
      },
      description: 'Elegant & versatile.',
      basePrice: 169.99,
      snapBasePrice: 14.99,
      snapDiscountedPrice: 13.49,
      oos: true,
    },
    Cover: {
      image: {
        Black: coverBlack,
        White: coverWhite,
      },
      snapImage: {
        Black: cover,
        White: cover,
      },
      snapFrameIndividualImage: {
        Black: individualCoverBlack,
      },
      description: 'Borderless & bold.',
      basePrice: 169.99,
      snapBasePrice: 14.99,
      snapDiscountedPrice: 13.49,
      oos: true,
    },
    Wood: {
      image: {
        Walnut: woodWalnut,
        Birch: woodBirch,
      },
      snapImage: {
        Walnut: wood,
        Birch: wood,
      },
      snapFrameIndividualImage: {
        Walnut: individualWoodWalnut,
        Birch: individualWoodBirch,
      },
      description: 'Warm, natural hardwood.',
      basePrice: 199.99,
      snapBasePrice: 29.99,
      snapDiscountedPrice: 26.99,
      oos: true,
    },
    'Shadow Box': {
      image: {
        Aluminum: shadowBoxAluminum,
        Charcoal: shadowBoxCharcoal,
      },
      snapImage: {
        Aluminum: shadowBox,
        Charcoal: shadowBox,
      },
      snapFrameIndividualImage: {
        Aluminum: individualShadowBoxAluminum,
      },
      description: 'Sleek & modern aluminum.',
      basePrice: 199.99,
      snapBasePrice: 29.99,
      snapDiscountedPrice: 26.99,
      oos: true,
    },
  }

  return styleConfig
}
