import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Quantity = styled.div`
  display: flex;
  margin: 15px 0px 0px;
  @media (max-width: ${breakpoints.l}px) {
    justify-content: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    justify-content: flex-start;
  }
`
export const QuantityInput = styled.div`
  font-family: 'FilsonProBook';
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100px;
  text-align: center;
  border-radius: 4px;
  height: 50px;
  padding: 15px;
  background-color: ${(props) => props.theme.cream};
  border: 1.5px solid rgba(68, 68, 68, 0.1);
  color: ${(props) => props.theme.textRegular};
  font-size: 16px;
  @media (max-width: ${breakpoints.s}px) {
    height: 40px;
  }
`
export const QuantityButton = styled.button`
  color: ${(props) => props.theme.grayDark};
  font-family: 'FilsonProBook';
  background: ${(props) => props.theme.cream};
  border: none;
  font-size: 18px;
  :focus,
  :hover,
  :active {
    border: none;
    outline: none;
    background-color: ${(props) => props.theme.cream};
  }
  cursor: pointer;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 14px;
    line-height: 14px;
  }
`
