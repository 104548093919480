import React, { useContext, useState, useRef, useEffect } from 'react'
import { navigate, graphql, useStaticQuery } from 'gatsby'
import StoreContext from 'src/context/StoreContext'
import { currencyCode, IS_FOREIGN_COUNTRY } from 'src/utils/constants'
import PropTypes from 'prop-types'
import Analytics from 'src/utils/Analytics'
import SEO from 'src/components/seo'
import { useStyleConfig } from './styleConfig'
import SnapFrame from '../../components/SnapFrame'
import { Container, Header, Text, ProductForm } from './styles'
import { ProductGridLeft, Grid, ProductImage } from '../FrameProductPage/styles'
import ReviewsSection from '../../components/ReviewsSection'
import Frame2ColorSelection from '../../components/Frame2ColorSelection'
import { Anchor } from '../../components/ReviewsSection/styles'

const getUtmParameter = (paramName) => {
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(paramName)
  }
  return null
}

const capitalize = (str) => str.replace(/\b\w/g, (char) => char.toUpperCase())

const handleSpecialCases = (style) => {
  if (style.toLowerCase() === 'shadowbox') {
    return 'Shadow Box'
  }
  return capitalize(style)
}

const SnapFrameProduct = ({ pageContext }) => {
  const { bundle } = useStaticQuery(graphql`
    query SnapFrameProductQuery {
      bundle: file(relativePath: { eq: "frame2/snap-frame-style.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  const { addToCart } = useContext(StoreContext)
  const { snapFrame } = pageContext
  const product = snapFrame.data.shopifyProduct
  const styleConfig = useStyleConfig()
  const initialStyle = handleSpecialCases(getUtmParameter('style') || 'Classic')
  const initialColor = capitalize(getUtmParameter('color') || 'Black')
  const [style, setStyle] = useState(initialStyle)
  const [color, setColor] = useState(initialColor)
  const [quantityVal, setQuantity] = useState(1)
  const [submitting, setSubmitting] = useState(false)
  const [stylesOrder, setStylesOrder] = useState(Object.keys(styleConfig))
  const [isMobile, setIsMobile] = useState(false)

  const scrollableStylesContainerRef = useRef(null)

  useEffect(() => {
    Analytics.track(`Viewed Snap ${style} ${color} Product Page`)
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth < 768)
      const handleResize = () => setIsMobile(window.innerWidth < 768)
      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  if (IS_FOREIGN_COUNTRY) {
    return null
  }

  const key = `${style} / ${color}`
  const variant = product.variants.find(({ title }) => title === key)
  const { image, title, price, shopifyId } = variant

  const handleStyleSelection = (selectedStyle) => {
    if (style !== 'Wood' && selectedStyle === 'Wood') {
      setColor('Walnut')
      Analytics.track(`Viewed Snap ${selectedStyle} Walnut Toggle Product Page`)
    } else if (style !== 'Shadow Box' && selectedStyle === 'Shadow Box') {
      setColor('Aluminum')
      Analytics.track(`Viewed Snap ${selectedStyle} Aluminum Toggle Product Page`)
    } else {
      setColor('Black')
      Analytics.track(`Viewed Snap ${selectedStyle} Black Toggle Product Page`)
    }
    setStyle(selectedStyle)

    if (isMobile) {
      const reorderedStyles = [
        selectedStyle,
        ...stylesOrder.filter((styleKey) => styleKey !== selectedStyle),
      ]
      setStylesOrder(reorderedStyles)

      if (scrollableStylesContainerRef.current) {
        scrollableStylesContainerRef.current.scrollTo({
          left: 0,
          behavior: 'smooth',
        })
      }
    }
  }

  const handleColorSelection = (selectedColor) => {
    Analytics.track(`Viewed Snap ${style} ${selectedColor} Toggle Product Page`)
    setColor(selectedColor)
  }

  const handleAddToCart = async (variantId, quantity) => {
    const id = variantId.split('/').pop()
    const pushToDataLayer = () => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode,
          add: {
            products: [
              {
                id,
                name: title,
                category: 'Snap Frame',
                price,
                quantity,
              },
            ],
          },
        },
      })
    }

    if (!submitting) {
      setSubmitting(true)
      Analytics.track(`Added Snap ${style} ${color} to Cart`)
      const items = [{ variantId, quantity }]
      const resp = await addToCart(items)
      if (resp && resp.error) {
        setSubmitting(false)
        alert(resp.error)
      } else {
        pushToDataLayer(variantId, {
          frame: variant,
          name: title,
        })
        navigate('/cart/')
      }
    }
  }

  const incrementQuantity = () => {
    setQuantity(quantityVal + 1)
  }

  const decrementQuantity = () => {
    if (quantityVal === 1) {
      return
    }
    setQuantity(quantityVal - 1)
  }

  const delayedUntilNov18 = () => style === 'Wood' && color === 'Walnut'

  const OOS = delayedUntilNov18()
  const shipDate = '11/18'

  return (
    <>
      <SEO
        title="Skylight Snap Frame"
        description="Choose from our new Snap Frame styles and colors - the perfect way to make your Frame 2
            feel at home."
        imagePath={bundle.childImageSharp.gatsbyImageData.images.fallback.src}
        path={`/products/${product.handle}/`}
      />
      <ProductForm>
        <Container>
          <Header>Snap Frames for Frame 2</Header>
          <Text>
            Choose from our new Snap Frame styles and colors - the perfect way to make your Frame 2
            feel at home.
          </Text>
        </Container>
        <Grid>
          <ProductGridLeft>
            <ProductImage
              image={image.gatsbyImageData}
              alt={`${style} ${color} Frame 2`}
              className="standard"
            />
          </ProductGridLeft>
          <Container showOnMobile>
            <Header>Snap Frames for Frame 2</Header>
            <Text>
              Choose from our new Snap Frame styles and colors - the perfect way to make your Frame
              2 feel at home.
            </Text>
          </Container>
          <Frame2ColorSelection
            image={image}
            style={style}
            color={color}
            handleStyleSelection={handleStyleSelection}
            handleColorSelection={handleColorSelection}
            styleConfig={styleConfig}
            stylesOrder={stylesOrder}
            scrollableStylesContainerRef={scrollableStylesContainerRef}
            OOS={OOS}
            shipDate={shipDate}
            quantityVal={quantityVal}
            decrementQuantity={decrementQuantity}
            incrementQuantity={incrementQuantity}
            submitting={submitting}
            handleAddToCart={handleAddToCart}
            shopifyId={shopifyId}
          />
        </Grid>
      </ProductForm>
      <SnapFrame page="snap" />
      <Anchor id="review" />
      <ReviewsSection page="snap" productId="7073751498804" name="The Skylight Frame" />
    </>
  )
}

SnapFrameProduct.propTypes = {
  pageContext: PropTypes.shape({
    snapFrame: PropTypes.object.isRequired,
  }).isRequired,
}

export default SnapFrameProduct
